import React from "react"
import Layout from "../components/layout"
import ImgAbout1 from "../images/about/1.jpg"
import ImgAbout2 from "../images/about/2.jpg"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import ContentContainer from "../components/contentcontainer"

export default function About({data}) {
  return (
    <ContentContainer>
      <Layout siteTitle="Über mich">
        <SEO
          title="Über mich | Babyfotografin in Köln"
          description="Natürliche Familienfotografin – Babybauchshooting, Newbornshooting und Babyshooting. Ich komme gerne zu euch nach Hause."
        />
        {/* Beachte: hier ist die Reihenfolge der COLs umgekehrt, als sie später dargestellt wird;
        sie wird wieder zurückgedreht über order-sm-1 bzw. order-sm-12
        Hintergrund: in mobile soll die rechte Hälfte der Seite (also die beiden Fotos) OBEN dargestellt werden, erst darunter dann der Text*/}
        <div className="row mx-sm-4">
          <div className="col-12 col-md-4 order-md-12 mb-4 mb-md-0">
            <div className="row mx-2">
              <div className="col-6 col-md-12">
                <img src={ImgAbout1} className="img-fluid mb-4" alt="image1" />
              </div>
              <div className="col-6 col-md-12">
                <img src={ImgAbout2} className="img-fluid" alt="image2" />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-8 mb-4 order-md-1">
            <div className="mx-3">
              <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
            </div>
          </div>
        </div>
      </Layout>
    </ContentContainer>
  )
}


/*
  Diese Funktion holt sich mit GraphQL den Markdown wird gefiltert nach dem Slug-Tag in der Datei
  in "html" ist dann das geparste Markdown als HTML String zu finden
  Übergeben werden die Daten an die Seitenfunktion über "data".
  Der Inhalt von Data lässt sich dann über {data.xxx} verwenden.
  Der HTML-String lässt sich über dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} injezieren
*/
export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: {slug: {eq: "/content/about"}}) {
      frontmatter {
        date
        slug
        title
      }
      html
      fileAbsolutePath
    }
  }
`